import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule]
})
export class RegisterComponent {
  constructor(
    private authService: AuthService
  ) { }

  readonly signUpForm = new FormGroup({
    email: new FormControl('', { validators: [Validators.required, Validators.email] }),
    password: new FormControl('', { validators: [Validators.required] }),
    password2: new FormControl('', { validators: [Validators.required] }),
  });

  signUp(): void {
    if (!this.signUpForm.valid) return;

    // TODO check password match

    const {
      email,
      password,
      // password2
    } = this.signUpForm.getRawValue();
    if (!email || !password) return;

    // this.authService.createAccount({ email, password });
  }
}
