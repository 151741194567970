import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TuiTableModule } from '@taiga-ui/addon-table';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiButtonModule, TuiRootModule } from '@taiga-ui/core';
import { Course } from '../../model/course';
import { CoursesService } from '../../services/courses.service';

@Component({
  selector: 'app-courses',
  standalone: true,
  imports: [CommonModule, TuiTableModule, TuiRootModule, TuiLetModule, TuiButtonModule],
  templateUrl: './courses.component.html',
  styleUrl: './courses.component.scss'
})
export class CoursesComponent {
  // TODO use keys of Course
  columns = ['name', 'holes', 'conditions', 'rating', 'actions'];

  coursesService = inject(CoursesService);

  courses = this.coursesService.getAll();

  edit(course: Course): void {
    console.log('__edit course', course.name);
  }

  remove(course: Course): void {
    console.log('__delete course', course.name);
  }
}
