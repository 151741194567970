import { Injectable, inject } from '@angular/core';
import { CollectionReference, Firestore, collection, collectionData, query } from '@angular/fire/firestore';
import { Observable, map } from 'rxjs';
import { Tournament, TournamentDto, toTournament } from '../model/tournament';

@Injectable({
  providedIn: 'root'
})
export class TournamentsService {
  fireStore = inject(Firestore);

  coursesCollection = collection(this.fireStore, 'tournaments') as CollectionReference<TournamentDto>;

  query = query(this.coursesCollection);

  constructor() { }

  getAll(): Observable<Tournament[]> {
    return collectionData(this.query, { idField: 'id' }).pipe(
      map(tournaments => (
        tournaments.map(toTournament) || []
      ))
    );
  }
}
