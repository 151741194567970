import { inject, Injectable } from '@angular/core';
import { Auth, authState, signInWithEmailAndPassword, signOut, User } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { TuiAlertService } from '@taiga-ui/core/components';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth = inject(Auth);
  private alert = inject(TuiAlertService);
  private router = inject(Router);

  currentUser$: Observable<User | null>;

  constructor() {
    this.currentUser$ = authState(this.auth);
  }

  login({ email, password }: { email: string; password: string }) {
    signInWithEmailAndPassword(this.auth, email, password)
      .then(() => {
        this.router.navigate(['/app']);
        this.alert.open(
          `Welcome back!`,
          {
            label: `Login successful`,
            status: 'success',
          }).subscribe();
      })
      .catch(() => {
        this.alert.open(
          `Error during login`,
          {
            label: `Login failed`,
            status: 'error',
          }).subscribe();
      });
  }

  logout() {
    signOut(this.auth)
      .then(() => {
        this.router.navigate(['/']);
        this.alert.open(
          `Farewell!`,
          {
            label: `Logged out`,
            status: 'success',
          }).subscribe();
      })
      .catch(() => {
        this.alert.open(
          `Error during logout`,
          {
            label: `Logout failed`,
            status: 'error',
          }).subscribe();
      });
  }
}
