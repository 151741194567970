<form class="login-form" [formGroup]="signUpForm">
  <span class="tui-text_h1 tui-space_bottom-10">New registration</span>

  <tui-input formControlName="email">
    Your email
    <input tuiTextfield type="email" />
  </tui-input>

  <tui-input formControlName="password">
    New password
    <input tuiTextfield type="password" />
  </tui-input>

  <tui-input formControlName="password2">
    Repeat password
    <input tuiTextfield type="password" />
  </tui-input>

  <button
    tuiButton
    type="button"
    (click)="signUp()"
  >
    Signup
  </button>

  <a routerLink="/"><- Back</a>
</form>
