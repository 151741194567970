import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuiTabsModule } from '@taiga-ui/kit';
import { TuiDataListModule, TuiDropdownModule, TuiHostedDropdownModule, TuiSvgModule } from '@taiga-ui/core';
import { tuiIsString } from '@taiga-ui/cdk';
import { ThemeSwitcherComponent } from '../theme-switcher/theme-switcher.component';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

export type HeaderNavItem = {
  label: string;
  routerLink?: string;
  id?: string;
  children?: HeaderNavItem[]
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, TuiTabsModule, TuiHostedDropdownModule, TuiSvgModule, TuiDataListModule, ThemeSwitcherComponent, TuiDropdownModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  router = inject(Router);
  auth = inject(AuthService);

  tabs: HeaderNavItem[] = [
      {
        label: 'Tournaments',
        routerLink: '/app/tournaments'
      },
      {
        label: 'Courses',
        routerLink: '/app/courses'
      },
      {
        label: 'Live Scoring',
        routerLink: '/app/live-scoring'
      },
      {
        label: 'Stats',
        routerLink: '/app/stats'
      },
      {
        label: 'User',
        children: [
          {
            label: 'Profile',
            routerLink: '/app/profile'
          },
          {
            label: 'Logout',
            id: 'logout'
          }
        ]
      },
  ];


  activeElement = this.tabs[0];

  open = false;

  get activeItemIndex(): number {
      return this.tabs.indexOf(this.activeElement);
  }

  stop(event: Event): void {
      // We need to stop tab custom event so parent component does not think its active
      event.stopPropagation();
  }

  onClick(activeElement: HeaderNavItem): void {
    this.activeElement = activeElement;
    this.open = false;

    if (activeElement.id === 'logout') {
      this.auth.logout();
    }

    if (activeElement.routerLink) {
      this.router.navigateByUrl(activeElement.routerLink);
    }
  }

  isString(tab: unknown): tab is string {
      return tuiIsString(tab);
  }
}
