import { TuiCountryIsoCode } from '@taiga-ui/i18n/enums';
const TUI_ENGLISH_LANGUAGE_ADDON_COMMERCE = {
  cardNumber: [`Number`, `Card number`],
  cardExpiry: [`Expires`, `Valid through`]
};
const TUI_ENGLISH_LANGUAGE_ADDON_EDITOR = {
  colorSelectorModeNames: [`Solid color`, `Gradient`],
  toolbarTools: {
    undo: `Undo`,
    redo: `Redo`,
    font: `Font`,
    fontStyle: `Font style`,
    fontSize: `Font size`,
    bold: `Bold`,
    italic: `Italic`,
    underline: `Underline`,
    strikeThrough: `Strike through`,
    justify: `Justify`,
    justifyLeft: `Justify left`,
    justifyCenter: `Justify center`,
    justifyRight: `Justify right`,
    justifyFull: `Justify full`,
    list: `List`,
    indent: `Indent`,
    outdent: `Outdent`,
    unorderedList: `Unordered list`,
    orderedList: `Ordered list`,
    quote: `Quote`,
    foreColor: `Color`,
    backColor: `Background color`,
    hiliteColor: `Highlight color`,
    clear: `Clear`,
    link: `Link`,
    attach: `Attach file`,
    tex: `Insert TeX`,
    code: `Code`,
    image: `Insert image`,
    insertHorizontalRule: `Insert horizontal rule`,
    superscript: `Superscript`,
    subscript: `Subscript`,
    insertTable: `Insert table`,
    insertGroup: `Insert group`,
    hiliteGroup: `Hilite group`,
    removeGroup: `Remove group`,
    insertAnchor: `Insert anchor`,
    mergeCells: `Merge cells`,
    splitCells: `Split cells`,
    rowsColumnsManaging: `Managing rows and columns`,
    cellColor: `Cell color`,
    setDetails: `Details`,
    removeDetails: `Remove details`
  },
  editorEditLink: {
    urlExample: `example.com`,
    anchorExample: `anchor`
  },
  editorTableCommands: [[`Insert column before`, `Insert column after`], [`Insert row before`, `Insert row after`], [`Delete column`, `Delete row`]],
  editorCodeOptions: [`Code in the text`, `Code in block`],
  editorFontOptions: {
    small: `Small`,
    large: `Large`,
    normal: `Normal`,
    title: `Title`,
    subtitle: `Subtitle`
  }
};
const TUI_ENGLISH_LANGUAGE_ADDON_PREVIEW = {
  previewTexts: {
    rotate: `Rotate`
  },
  zoomTexts: {
    zoomOut: `Zoom out`,
    zoomIn: `Zoom in`,
    reset: `Reset`
  }
};
const TUI_ENGLISH_LANGUAGE_ADDON_TABLE = {
  showHideText: `Show/Hide`,
  paginationTexts: {
    pages: `Pages`,
    linesPerPage: `Lines per page`,
    of: `of`
  }
};
const TUI_ENGLISH_LANGUAGE_COUNTRIES = {
  [TuiCountryIsoCode.AD]: `Andorra`,
  [TuiCountryIsoCode.AE]: `United Arab Emirates`,
  [TuiCountryIsoCode.AF]: `Afghanistan`,
  [TuiCountryIsoCode.AG]: `Antigua and Barbuda`,
  [TuiCountryIsoCode.BL]: `Saint Barthélemy`,
  [TuiCountryIsoCode.AI]: `Anguilla`,
  [TuiCountryIsoCode.AL]: `Albania`,
  [TuiCountryIsoCode.AM]: `Armenia`,
  [TuiCountryIsoCode.AO]: `Angola`,
  [TuiCountryIsoCode.AR]: `Argentina`,
  [TuiCountryIsoCode.AT]: `Austria`,
  [TuiCountryIsoCode.AU]: `Australia`,
  [TuiCountryIsoCode.AW]: `Aruba`,
  [TuiCountryIsoCode.AZ]: `Azerbaijan`,
  [TuiCountryIsoCode.BA]: `Bosnia and Herzegovina`,
  [TuiCountryIsoCode.BB]: `Barbados`,
  [TuiCountryIsoCode.BD]: `Bangladesh`,
  [TuiCountryIsoCode.BE]: `Belgium`,
  [TuiCountryIsoCode.BF]: `Burkina Faso`,
  [TuiCountryIsoCode.BG]: `Bulgaria`,
  [TuiCountryIsoCode.BH]: `Bahrain`,
  [TuiCountryIsoCode.BI]: `Burundi`,
  [TuiCountryIsoCode.BJ]: `Benin`,
  [TuiCountryIsoCode.GF]: `Guyana`,
  [TuiCountryIsoCode.BM]: `Bermuda`,
  [TuiCountryIsoCode.BN]: `Brunei Darussalam`,
  [TuiCountryIsoCode.BO]: `Bolivia`,
  [TuiCountryIsoCode.BQ]: `Bonaire, Sint Eustatius and Saba`,
  [TuiCountryIsoCode.BR]: `Brazil`,
  [TuiCountryIsoCode.BS]: `Bahamas`,
  [TuiCountryIsoCode.BT]: `Bhutan`,
  [TuiCountryIsoCode.BW]: `Botswana`,
  [TuiCountryIsoCode.BY]: `Belarus`,
  [TuiCountryIsoCode.BZ]: `Belize`,
  [TuiCountryIsoCode.CA]: `Canada`,
  [TuiCountryIsoCode.CD]: `Dem. Rep. Congo (Kinshasa)`,
  [TuiCountryIsoCode.CF]: `Central African Republic`,
  [TuiCountryIsoCode.CG]: `Congo (Brazzaville)`,
  [TuiCountryIsoCode.CH]: `Switzerland`,
  [TuiCountryIsoCode.CI]: `Ivory Coast`,
  [TuiCountryIsoCode.CL]: `Chile`,
  [TuiCountryIsoCode.CM]: `Cameroon`,
  [TuiCountryIsoCode.CN]: `China`,
  [TuiCountryIsoCode.CO]: `Colombia`,
  [TuiCountryIsoCode.CR]: `Costa Rica`,
  [TuiCountryIsoCode.CU]: `Cuba`,
  [TuiCountryIsoCode.CV]: `Cape Verde`,
  [TuiCountryIsoCode.CW]: `Curaçao`,
  [TuiCountryIsoCode.CY]: `Cyprus`,
  [TuiCountryIsoCode.CZ]: `Czech Republic`,
  [TuiCountryIsoCode.DE]: `Germany`,
  [TuiCountryIsoCode.DJ]: `Djibouti`,
  [TuiCountryIsoCode.DK]: `Denmark`,
  [TuiCountryIsoCode.DM]: `Dominica`,
  [TuiCountryIsoCode.DO]: `Dominican Republic`,
  [TuiCountryIsoCode.DZ]: `Algeria`,
  [TuiCountryIsoCode.EC]: `Ecuador`,
  [TuiCountryIsoCode.EE]: `Estonia`,
  [TuiCountryIsoCode.EG]: `Egypt`,
  [TuiCountryIsoCode.ER]: `Eritrea`,
  [TuiCountryIsoCode.ES]: `Spain`,
  [TuiCountryIsoCode.ET]: `Ethiopia`,
  [TuiCountryIsoCode.FI]: `Finland`,
  [TuiCountryIsoCode.FJ]: `Fiji`,
  [TuiCountryIsoCode.FK]: `Falkland Islands`,
  [TuiCountryIsoCode.FM]: `F.S. of Micronesia`,
  [TuiCountryIsoCode.FR]: `France`,
  [TuiCountryIsoCode.GA]: `Gabon`,
  [TuiCountryIsoCode.GB]: `United Kingdom`,
  [TuiCountryIsoCode.GD]: `Grenada`,
  [TuiCountryIsoCode.GE]: `Georgia`,
  [TuiCountryIsoCode.GP]: `Guadeloupe`,
  [TuiCountryIsoCode.GH]: `Ghana`,
  [TuiCountryIsoCode.GI]: `Gibraltar`,
  [TuiCountryIsoCode.GL]: `Greenland`,
  [TuiCountryIsoCode.GM]: `Gambia`,
  [TuiCountryIsoCode.GN]: `Guinea`,
  [TuiCountryIsoCode.MF]: `Saint-Martin`,
  [TuiCountryIsoCode.GQ]: `Equatorial Guinea`,
  [TuiCountryIsoCode.GR]: `Greece`,
  [TuiCountryIsoCode.GT]: `Guatemala`,
  [TuiCountryIsoCode.GW]: `Guinea-Bissau`,
  [TuiCountryIsoCode.GY]: `Guyana`,
  [TuiCountryIsoCode.HK]: `Hong Kong`,
  [TuiCountryIsoCode.HN]: `Honduras`,
  [TuiCountryIsoCode.HR]: `Croatia`,
  [TuiCountryIsoCode.HT]: `Haiti`,
  [TuiCountryIsoCode.HU]: `Hungary`,
  [TuiCountryIsoCode.ID]: `Indonesia`,
  [TuiCountryIsoCode.IE]: `Ireland`,
  [TuiCountryIsoCode.IL]: `Israel`,
  [TuiCountryIsoCode.IN]: `India`,
  [TuiCountryIsoCode.IQ]: `Iraq`,
  [TuiCountryIsoCode.IR]: `Iran`,
  [TuiCountryIsoCode.IS]: `Iceland`,
  [TuiCountryIsoCode.IT]: `Italy`,
  [TuiCountryIsoCode.JM]: `Jamaica`,
  [TuiCountryIsoCode.JO]: `Jordan`,
  [TuiCountryIsoCode.JP]: `Japan`,
  [TuiCountryIsoCode.KE]: `Kenya`,
  [TuiCountryIsoCode.KG]: `Kyrgyzstan`,
  [TuiCountryIsoCode.KH]: `Cambodia`,
  [TuiCountryIsoCode.KM]: `Comoros`,
  [TuiCountryIsoCode.KN]: `Saint Kitts and Nevis`,
  [TuiCountryIsoCode.KP]: `Korean PDR`,
  [TuiCountryIsoCode.KR]: `Rep. Korea`,
  [TuiCountryIsoCode.KW]: `Kuwait`,
  [TuiCountryIsoCode.KY]: `Cayman Islands`,
  [TuiCountryIsoCode.KZ]: `Kazakhstan`,
  [TuiCountryIsoCode.LA]: `Laos`,
  [TuiCountryIsoCode.LB]: `Lebanon`,
  [TuiCountryIsoCode.LC]: `Saint Lucia`,
  [TuiCountryIsoCode.LI]: `Liechtenstein`,
  [TuiCountryIsoCode.LK]: `Sri Lanka`,
  [TuiCountryIsoCode.LR]: `Liberia`,
  [TuiCountryIsoCode.LS]: `Lesotho`,
  [TuiCountryIsoCode.LT]: `Lithuania`,
  [TuiCountryIsoCode.LU]: `Luxembourg`,
  [TuiCountryIsoCode.LV]: `Latvia`,
  [TuiCountryIsoCode.LY]: `Libya`,
  [TuiCountryIsoCode.MA]: `Morocco`,
  [TuiCountryIsoCode.MC]: `Monaco`,
  [TuiCountryIsoCode.MD]: `Moldova`,
  [TuiCountryIsoCode.ME]: `Montenegro`,
  [TuiCountryIsoCode.MQ]: `Martinique`,
  [TuiCountryIsoCode.MG]: `Madagascar`,
  [TuiCountryIsoCode.MK]: `Rep. of North Macedonia`,
  [TuiCountryIsoCode.ML]: `Mali`,
  [TuiCountryIsoCode.MM]: `Burma (Myanmar)`,
  [TuiCountryIsoCode.MN]: `Mongolia`,
  [TuiCountryIsoCode.MO]: `Macau`,
  [TuiCountryIsoCode.RE]: `Réunion`,
  [TuiCountryIsoCode.MR]: `Mauritania`,
  [TuiCountryIsoCode.MS]: `Montserrat`,
  [TuiCountryIsoCode.MT]: `Malta`,
  [TuiCountryIsoCode.MU]: `Mauritius`,
  [TuiCountryIsoCode.MV]: `Maldives`,
  [TuiCountryIsoCode.MW]: `Malawi`,
  [TuiCountryIsoCode.MX]: `Mexico`,
  [TuiCountryIsoCode.MY]: `Malaysia`,
  [TuiCountryIsoCode.MZ]: `Mozambique`,
  [TuiCountryIsoCode.NA]: `Namibia`,
  [TuiCountryIsoCode.NC]: `New Caledonia`,
  [TuiCountryIsoCode.NE]: `Niger`,
  [TuiCountryIsoCode.NG]: `Nigeria`,
  [TuiCountryIsoCode.NI]: `Nicaragua`,
  [TuiCountryIsoCode.NL]: `Netherlands`,
  [TuiCountryIsoCode.NO]: `Norway`,
  [TuiCountryIsoCode.NP]: `Nepal`,
  [TuiCountryIsoCode.NZ]: `New Zealand`,
  [TuiCountryIsoCode.OM]: `Oman`,
  [TuiCountryIsoCode.PA]: `Panama`,
  [TuiCountryIsoCode.PE]: `Peru`,
  [TuiCountryIsoCode.PF]: `French Polynesia (Tahiti)`,
  [TuiCountryIsoCode.PG]: `Papua New Guinea`,
  [TuiCountryIsoCode.PH]: `Philippines`,
  [TuiCountryIsoCode.PK]: `Pakistan`,
  [TuiCountryIsoCode.PL]: `Poland`,
  [TuiCountryIsoCode.PT]: `Portugal`,
  [TuiCountryIsoCode.PW]: `Palau`,
  [TuiCountryIsoCode.PY]: `Paraguay`,
  [TuiCountryIsoCode.QA]: `Qatar`,
  [TuiCountryIsoCode.YT]: `Mayotte`,
  [TuiCountryIsoCode.RO]: `Romania`,
  [TuiCountryIsoCode.RS]: `Serbia`,
  [TuiCountryIsoCode.RU]: `Russia`,
  [TuiCountryIsoCode.RW]: `Rwanda`,
  [TuiCountryIsoCode.SA]: `Saudi Arabia`,
  [TuiCountryIsoCode.SB]: `Solomon Islands`,
  [TuiCountryIsoCode.SC]: `Seychelles`,
  [TuiCountryIsoCode.SD]: `Sudan`,
  [TuiCountryIsoCode.SE]: `Sweden`,
  [TuiCountryIsoCode.SG]: `Singapore`,
  [TuiCountryIsoCode.SH]: `Saint Helena`,
  [TuiCountryIsoCode.SI]: `Slovenia`,
  [TuiCountryIsoCode.SK]: `Slovakia`,
  [TuiCountryIsoCode.SL]: `Sierra Leone`,
  [TuiCountryIsoCode.SM]: `San Marino`,
  [TuiCountryIsoCode.SN]: `Senegal`,
  [TuiCountryIsoCode.SO]: `Somalia`,
  [TuiCountryIsoCode.SR]: `Suriname`,
  [TuiCountryIsoCode.ST]: `Sao Tome and Principe`,
  [TuiCountryIsoCode.SV]: `El Salvador`,
  [TuiCountryIsoCode.SX]: `Sint Maarten`,
  [TuiCountryIsoCode.SY]: `Syrian Arab Republic`,
  [TuiCountryIsoCode.SZ]: `Swaziland`,
  [TuiCountryIsoCode.TC]: `Turks and Caicos`,
  [TuiCountryIsoCode.TD]: `Chad`,
  [TuiCountryIsoCode.TG]: `Togo`,
  [TuiCountryIsoCode.TH]: `Thailand`,
  [TuiCountryIsoCode.TJ]: `Tajikistan`,
  [TuiCountryIsoCode.TL]: `East Timor`,
  [TuiCountryIsoCode.TM]: `Turkmenistan`,
  [TuiCountryIsoCode.TN]: `Tunisia`,
  [TuiCountryIsoCode.TO]: `Tonga`,
  [TuiCountryIsoCode.TR]: `Turkey`,
  [TuiCountryIsoCode.TT]: `Trinidad and Tobago`,
  [TuiCountryIsoCode.TW]: `Taiwan`,
  [TuiCountryIsoCode.TZ]: `Tanzania`,
  [TuiCountryIsoCode.UA]: `Ukraine`,
  [TuiCountryIsoCode.UG]: `Uganda`,
  [TuiCountryIsoCode.US]: `USA`,
  [TuiCountryIsoCode.UY]: `Uruguay`,
  [TuiCountryIsoCode.UZ]: `Uzbekistan`,
  [TuiCountryIsoCode.VC]: `Saint Vincent and the Grenadines`,
  [TuiCountryIsoCode.VE]: `Venezuela`,
  [TuiCountryIsoCode.VG]: `British Virgin Islands`,
  [TuiCountryIsoCode.VN]: `Vietnam`,
  [TuiCountryIsoCode.VU]: `Vanuatu`,
  [TuiCountryIsoCode.WS]: `Samoa`,
  [TuiCountryIsoCode.XK]: `Kosovo`,
  [TuiCountryIsoCode.YE]: `Yemen`,
  [TuiCountryIsoCode.ZA]: `South Africa`,
  [TuiCountryIsoCode.ZM]: `Zambia`,
  [TuiCountryIsoCode.ZW]: `Zimbabwe`
};
const TUI_ENGLISH_LANGUAGE_CORE = {
  months: [`January`, `February`, `March`, `April`, `May`, `June`, `July`, `August`, `September`, `October`, `November`, `December`],
  close: `Close`,
  nothingFoundMessage: `Nothing found`,
  defaultErrorMessage: `Value is invalid`,
  spinTexts: [`Previous`, `Next`],
  shortWeekDays: [`Mon`, `Tue`, `Wed`, `Thu`, `Fri`, `Sat`, `Sun`],
  countries: TUI_ENGLISH_LANGUAGE_COUNTRIES
};
const TUI_ENGLISH_LANGUAGE_KIT = {
  cancel: `Cancel`,
  done: `Done`,
  more: `More`,
  otherDate: `Other date...`,
  showAll: `Show all`,
  hide: `Hide`,
  mobileCalendarTexts: [`Choose day`, `Choose range`],
  range: [`from`, `to`],
  countTexts: [`Plus`, `Minus`],
  time: {
    'HH:MM': `HH:MM`,
    'HH:MM:SS': `HH:MM:SS`,
    'HH:MM:SS.MSS': `HH:MM:SS.MSS`
  },
  dateTexts: {
    DMY: `dd.mm.yyyy`,
    MDY: `mm.dd.yyyy`,
    YMD: `yyyy.mm.dd`
  },
  digitalInformationUnits: [`B`, `KiB`, `MiB`],
  passwordTexts: [`Show password`, `Hide password`],
  copyTexts: [`Copy`, `Copied`],
  shortCalendarMonths: [`Jan`, `Feb`, `Mar`, `Apr`, `May`, `Jun`, `Jul`, `Aug`, `Sep`, `Oct`, `Nov`, `Dec`],
  pagination: [`Previous page`, `Next page`],
  fileTexts: {
    loadingError: `Upload failed`,
    preview: `Preview`,
    remove: `Remove`
  },
  inputFileTexts: {
    defaultLabelSingle: `or drop\u00A0it\u00A0here`,
    defaultLabelMultiple: `or drop\u00A0them\u00A0here`,
    defaultLinkSingle: `Choose a file`,
    defaultLinkMultiple: `Choose files`,
    maxSizeRejectionReason: `File is too large `,
    formatRejectionReason: `Wrong file type`,
    drop: `Drop file here`,
    dropMultiple: `Drop files here`
  },
  multiSelectTexts: {
    all: `Select all`,
    none: `Select none`
  },
  prompt: {
    yes: `Yes`,
    no: `No`
  }
};
const TUI_ENGLISH_LANGUAGE = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({
  name: `english`
}, TUI_ENGLISH_LANGUAGE_CORE), TUI_ENGLISH_LANGUAGE_KIT), TUI_ENGLISH_LANGUAGE_ADDON_TABLE), TUI_ENGLISH_LANGUAGE_ADDON_COMMERCE), TUI_ENGLISH_LANGUAGE_ADDON_EDITOR), TUI_ENGLISH_LANGUAGE_ADDON_PREVIEW);

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_ENGLISH_LANGUAGE, TUI_ENGLISH_LANGUAGE_ADDON_COMMERCE, TUI_ENGLISH_LANGUAGE_ADDON_EDITOR, TUI_ENGLISH_LANGUAGE_ADDON_PREVIEW, TUI_ENGLISH_LANGUAGE_ADDON_TABLE, TUI_ENGLISH_LANGUAGE_CORE, TUI_ENGLISH_LANGUAGE_COUNTRIES, TUI_ENGLISH_LANGUAGE_KIT };
