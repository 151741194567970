import { InjectionToken, Optional, SkipSelf } from '@angular/core';
import { tuiAssert } from '@taiga-ui/cdk/classes';
import { FormArray, FormGroup } from '@angular/forms';
function tuiArrayRemove(array, index) {
  return array.slice(0, Math.max(index, 0)).concat(array.slice(Math.max(index + 1, 0)));
}
function tuiArrayShallowEquals(a, b) {
  return a.length === b.length && a.every((item, index) => item === b[index]);
}
function tuiArrayToggle(array, item) {
  const index = array.indexOf(item);
  return index === -1 ? [...array, item] : tuiArrayRemove(array, index);
}
function tuiIsString(value) {
  // eslint-disable-next-line @taiga-ui/experience/no-typeof
  return typeof value === `string`;
}
function tuiCleanObject(object) {
  return JSON.parse(JSON.stringify(object, (_key, value) => checkValueIsEmpty(value) ? undefined : value));
}
function checkValueIsEmpty(value) {
  const nextValue = tuiIsString(value) ? value.trim() : value;
  return [undefined, null, NaN, ``].includes(nextValue);
}
function tuiCreateToken(defaults) {
  return tuiCreateTokenFromFactory(() => defaults);
}
function tuiCreateTokenFromFactory(factory) {
  return new InjectionToken(``, {
    factory
  });
}

/** @deprecated use {@link tuiCreateToken} instead */
function tuiCreateOptions(defaults) {
  return tuiCreateTokenFromFactory(() => defaults);
}
function tuiDefaultSort(x, y) {
  if (x === y) {
    return 0;
  }
  if (tuiIsString(x) && tuiIsString(y)) {
    return x.localeCompare(y);
  }
  return x > y ? 1 : -1;
}
function tuiDistanceBetweenTouches({
  touches
}) {
  return Math.hypot(touches[0].clientX - touches[1].clientX, touches[0].clientY - touches[1].clientY);
}
function tuiEaseInOutQuad(t) {
  ngDevMode && tuiAssert.assert(t >= 0 && t <= 1, `Input must be between 0 and 1 inclusive but received `, t);
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

/**
 * Flattens two-dimensional array and calculates resulting length
 *
 * @param array twi dimensional array
 */
function tuiFlatLength(array) {
  return array.reduce((count, section) => count + section.length, 0);
}

/**
 * Extracts original array from {@link QueryList} rather than
 * creating a copy like {@link QueryList.toArray} does.
 * @param queryList
 * @returns original array from {@link QueryList}.
 */
function tuiGetOriginalArrayFromQueryList(queryList) {
  let array = [];
  queryList.find((_item, _index, originalArray) => {
    array = originalArray;
    return true;
  });
  return array;
}
function tuiGetSwipeDirection(deltaX, deltaY) {
  if (Math.abs(deltaY) > Math.abs(deltaX)) {
    return deltaY > 0 ? `top` : `bottom`;
  }
  return deltaX > 0 ? `left` : `right`;
}
function tuiIsFalsy(value) {
  return !value;
}
function tuiIsNumber(value) {
  // eslint-disable-next-line @taiga-ui/experience/no-typeof
  return typeof value === `number`;
}
function tuiIsObject(value) {
  // eslint-disable-next-line @taiga-ui/experience/no-typeof
  return typeof value === `object` && !!value;
}
function tuiIsPresent(value) {
  return value !== null && value !== undefined;
}
function tuiIsValidUrl(url) {
  const pattern = new RegExp(`^([a-zA-Z]+:\\/\\/)?` +
  // protocol
  `((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|localhost|` +
  // domain name
  `((\\d{1,3}\\.){3}\\d{1,3}))` +
  // OR IP (v4) address
  `(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*` +
  // port and path
  `(\\?[;&a-z\\d%_.~+=-]*)?` +
  // query string
  `(\\#[-a-z\\d_]*)?$`,
  // fragment locator
  `i`);
  return pattern.test(url);
}
function tuiMarkControlAsTouchedAndValidate(control) {
  if (control instanceof FormArray) {
    control.controls.forEach(nestedControl => {
      tuiMarkControlAsTouchedAndValidate(nestedControl);
    });
  }
  if (control instanceof FormGroup) {
    Object.values(control.controls).forEach(nestedControl => {
      tuiMarkControlAsTouchedAndValidate(nestedControl);
    });
  }
  control.markAsTouched();
  control.updateValueAndValidity();
}

/**
 * Checks identity for nullable elements.
 *
 * @param a element a
 * @param b element b
 * @param handler called if both elements are not null
 * @return true if either both are null or they pass identity handler
 */
function tuiNullableSame(a, b, handler) {
  if (a === null) {
    return b === null;
  }
  if (b === null) {
    return false;
  }
  return handler(a, b);
}

/**
 * @deprecated use `Object.fromEntries` instead
 * (check browser support first https://caniuse.com/mdn-javascript_builtins_object_fromentries)
 * ___
 * TODO: after we bump Firefox to 63+ replace this function with `Object.fromEntries`.
 * TODO: Add `es2019.object` to `tsconfig.json` => `compilerOptions.lib`.
 *
 */
function tuiObjectFromEntries(keyValuePairs) {
  return keyValuePairs.reduce((obj, [key, val]) => Object.assign(Object.assign({}, obj), {
    [key]: val
  }), {});
}
function tuiProvideOptions(provide, options, fallback) {
  return {
    provide,
    deps: [[new Optional(), new SkipSelf(), provide]],
    useFactory: parent => Object.assign(Object.assign({}, parent || fallback), options)
  };
}
function tuiUniqBy(array, key) {
  return Array.from(array.reduce((map, item) => map.has(item[key]) ? map : map.set(item[key], item), new Map()).values());
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiArrayRemove, tuiArrayShallowEquals, tuiArrayToggle, tuiCleanObject, tuiCreateOptions, tuiCreateToken, tuiCreateTokenFromFactory, tuiDefaultSort, tuiDistanceBetweenTouches, tuiEaseInOutQuad, tuiFlatLength, tuiGetOriginalArrayFromQueryList, tuiGetSwipeDirection, tuiIsFalsy, tuiIsNumber, tuiIsObject, tuiIsPresent, tuiIsString, tuiIsValidUrl, tuiMarkControlAsTouchedAndValidate, tuiNullableSame, tuiObjectFromEntries, tuiProvideOptions, tuiUniqBy };
