import { Injectable, inject } from '@angular/core';
import { CollectionReference, Firestore, collection, collectionData, query } from '@angular/fire/firestore';
import { Observable, map } from 'rxjs';
import { Course } from '../model/course';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {
  fireStore = inject(Firestore);

  coursesCollection = collection(this.fireStore, 'courses') as CollectionReference<Course>;

  query = query(this.coursesCollection);

  constructor() { }

  getAll(): Observable<Course[]> {
    return collectionData(this.query).pipe(
      map(courses => (courses || []))
    );
  }
}
