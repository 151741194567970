import { InjectionToken, inject } from '@angular/core';
import { TUI_ENGLISH_LANGUAGE } from '@taiga-ui/i18n/languages/english';
import { of } from 'rxjs';

/**
 * Default Language for Taiga UI libraries i18n
 */
const TUI_DEFAULT_LANGUAGE = new InjectionToken(`[TUI_DEFAULT_LANGUAGE]`, {
  factory: () => TUI_ENGLISH_LANGUAGE
});
/**
 * Language for Taiga UI libraries i18n
 */
const TUI_LANGUAGE = new InjectionToken(`[TUI_LANGUAGE]`, {
  factory: () => of(inject(TUI_DEFAULT_LANGUAGE))
});

/**
 * Webpack chunk loader for Taiga UI libraries i18n
 * @note: cannot be transferred to a shared file
 * ReferenceError: Cannot access 'TUI_LANGUAGE_LOADER' before initialization
 */
const TUI_LANGUAGE_LOADER = new InjectionToken(`[TUI_LANGUAGE_LOADER]`);

/**
 * Default key for search value in storage
 */
const TUI_LANGUAGE_STORAGE_KEY = new InjectionToken(`[TUI_LANGUAGE_STORAGE_KEY]`, {
  factory: () => `tuiLanguage`
});

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_DEFAULT_LANGUAGE, TUI_LANGUAGE, TUI_LANGUAGE_LOADER, TUI_LANGUAGE_STORAGE_KEY };
