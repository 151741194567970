<table tuiTable [columns]="columns">
  <thead>
    <tr tuiThGroup>
      <th *tuiHead="'name'" tuiTh>Name</th>
      <th *tuiHead="'holes'" tuiTh>Holes</th>
      <th *tuiHead="'conditions'" tuiTh>Conditions</th>
      <th *tuiHead="'rating'" tuiTh>Rating</th>
      <th *tuiHead="'actions'" tuiTh [sorter]="null"></th>
    </tr>
  </thead>
  @if(courses | async; as courses) {
  <tbody
    *tuiLet="courses | tuiTableSort as sortedCourses"
    tuiTbody
    [data]="sortedCourses"
  >
    <tr *ngFor="let item of sortedCourses; let index = index" tuiTr>
      <td *tuiCell="'name'" tuiTd>
        {{ item["name"] }}
      </td>
      <td *tuiCell="'holes'" tuiTd>
        {{ item["holes"] }}
      </td>
      <td *tuiCell="'conditions'" tuiTd>
        {{ item["conditions"] }}
      </td>
      <td *tuiCell="'rating'" tuiTd>
        {{ item["rating"] }}
      </td>
      <td *tuiCell="'actions'" tuiTd>
        <button
          appearance="flat"
          icon="tuiIconEdit2"
          shape="rounded"
          size="s"
          title="Edit"
          tuiIconButton
          type="button"
          (click)="edit(item)"
          class="action-button"
        ></button>
        <button
          appearance="flat"
          icon="tuiIconTrash"
          shape="rounded"
          size="s"
          title="Remove"
          tuiIconButton
          type="button"
          (click)="remove(item)"
          class="action-button"
        ></button>
      </td>
    </tr>
  </tbody>
  }
</table>
