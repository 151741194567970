import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { HomeComponent } from './inside/home/home.component';
import { ProfileComponent } from './inside/profile/profile.component';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './login/register/register.component';
import { CoursesComponent } from './inside/courses/courses.component';
import { TournamentsComponent } from './inside/tournaments/tournaments.component';

export const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    children: [
      {
        path: '',
        component: LoginFormComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
    ]
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    component: HomeComponent,
    children: [
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'courses',
        component: CoursesComponent,
      },
      {
        path: 'tournaments',
        component: TournamentsComponent,
      },
    ],
  },
];
