<div
  tuiMode="onDark"
  class="wrapper d-flex align-items-center p-2 pb-0 pt-1 gap-2"
>
  <h1 class="tui-text_h3 title m-0 mb-1">DG Marker</h1>

  <tui-tabs-with-more
    class="tabs flex-grow-1 justify-content-end"
    [activeItemIndex]="activeItemIndex"
    [moreContent]="more"
  >
    @for (tab of tabs; track $index) {
      @if(!tab.children) {
        <button *tuiItem tuiTab class="px-1 pb-1" (click)="onClick(tab)">
          {{ tab.label }}
        </button>
      } @else {
        <tui-hosted-dropdown *tuiItem [content]="dropdown" [(open)]="open">
          <button tuiTab class="px-1 pb-1" (tui-tab-activate)="stop($event)">
            {{ tab.label}}
            <tui-svg src="tuiIconChevronDown" class="icon" [class.icon_rotated]="open" />
          </button>
        </tui-hosted-dropdown>

        <ng-template #dropdown>
          <tui-data-list>
            <button *ngFor="let option of tab.children" tuiOption (click)="onClick(option)">
              {{ option.label }}
            </button>
          </tui-data-list>
        </ng-template>
      }
    }
  </tui-tabs-with-more>

  <app-theme-switcher />
</div>

<ng-template #more>
  <tui-svg src="tuiIconMoreHorizontalLarge" />
</ng-template>
