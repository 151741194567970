export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyA41_qY6tqTLjgTKZ0zVbgxR8RLH1pLYTE",
    authDomain: "dgmarker-33245.firebaseapp.com",
    projectId: "dgmarker-33245",
    storageBucket: "dgmarker-33245.appspot.com",
    messagingSenderId: "177711785367",
    appId: "1:177711785367:web:61965c407ddee4280450d2",
    measurementId: "G-0YDF9ZZ1JJ"
  }
};
