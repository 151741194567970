import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TuiButtonModule } from '@taiga-ui/core';
import { TuiInputModule } from '@taiga-ui/kit';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TuiInputModule, TuiButtonModule, RouterModule],
})
export class LoginFormComponent {
  private authService = inject(AuthService);

  readonly loginForm = new FormGroup({
    email: new FormControl('', { validators: [Validators.required, Validators.email] }),
    password: new FormControl('', { validators: [Validators.required] }),
  });

  login(): void {
    if (!this.loginForm.valid) return;

    const {
      email,
      password
    } = this.loginForm.getRawValue();
    if (!email || !password) return;

    this.authService.login({ email, password });
  }
}
