import { QueryList } from '@angular/core';
import { trigger, transition, query, animateChild } from '@angular/animations';

/**
 * Handler that always returns `false`.
 */
// eslint-disable-next-line no-restricted-syntax
const ALWAYS_FALSE_HANDLER = () => false;

/**
 * Handler that always returns `true`.
 */
// eslint-disable-next-line no-restricted-syntax
const ALWAYS_TRUE_HANDLER = () => true;

/**
 * @deprecated
 * TODO: drop support of legacy Edge (EdgeHTML) in v4.x
 */
const CHROMIUM_EDGE_START_VERSION = 79;

/**
 * For type safety when using @ContentChildren and @ViewChildren
 *
 * NOTE: Be careful subscribing to 'changes'
 */
const EMPTY_QUERY = new QueryList();
const EMPTY_ARRAY = [];
const EMPTY_FUNCTION = () => {};
const rect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0
};
const EMPTY_CLIENT_RECT = Object.assign(Object.assign({}, rect), {
  toJSON() {
    return rect;
  }
});

/**
 * Default method to turn arbitrary object into string
 */
const TUI_DEFAULT_STRINGIFY = item => String(item);

/**
 * Default handler for matching stringified version of an item and a search query
 * @param item arbitrary element to match with a string
 * @param search search query
 * @param stringify handler to turn item into a string
 */
const TUI_DEFAULT_MATCHER = (item, search, stringify = TUI_DEFAULT_STRINGIFY) => stringify(item).toLowerCase().includes(search.toLowerCase());
/**
 * Default handler for strict matching stringified version of an item and a search query
 * @param item arbitrary element to match with a string
 * @param search search query
 * @param stringify handler to turn item into a string
 */
const TUI_STRICT_MATCHER = (item, search, stringify = TUI_DEFAULT_STRINGIFY) => stringify(item).toLowerCase() === search.toLowerCase();
/**
 * Default handler to match equality of two elements
 * ATTENTION: considers two empty arrays equal
 *
 * @param item1 first element
 * @param item2 second element
 */
const TUI_DEFAULT_IDENTITY_MATCHER = (item1, item2) => item1 === item2 || bothEmpty(item1, item2);
function bothEmpty(item1, item2) {
  return Array.isArray(item1) && Array.isArray(item2) && !item1.length && !item2.length;
}

/**
 * Add to enable child :leave animation (fixes https://github.com/angular/angular/issues/15753)
 */
const TUI_PARENT_ANIMATION = trigger(`tuiParentAnimation`, [transition(`:leave`, [query(`:scope > *`, [animateChild()], {
  optional: true
})])]);
/**
 * Add on parent to stop initial :enter animation for children
 */
const TUI_PARENT_STOP = trigger(`tuiParentStop`, [transition(`:enter`, [])]);
const POLLING_TIME = 1000 / 15;

// Filtering SVGElements for TreeWalker
// Filter must be a function in IE, other modern browsers are compliant to this format
const svgNodeFilter = node => `ownerSVGElement` in node ? NodeFilter.FILTER_REJECT : NodeFilter.FILTER_ACCEPT;

/**
 * {@link https://unicode-table.com/en/00A0/ Non-breaking space}.
 */
const CHAR_NO_BREAK_SPACE = `\u00A0`;
/**
 * {@link https://unicode-table.com/en/2013/ EN dash}
 * is used to indicate a range of numbers or a span of time.
 * @example 2006–2022
 * ___
 * Don't confuse with {@link CHAR_EM_DASH} or {@link CHAR_HYPHEN}!
 */
const CHAR_EN_DASH = `\u2013`;
/**
 * {@link https://unicode-table.com/en/2014/ EM dash}
 * is used to mark a break in a sentence.
 * @example Taiga UI — powerful set of open source components for Angular
 * ___
 * Don't confuse with {@link CHAR_EN_DASH} or {@link CHAR_HYPHEN}!
 */
const CHAR_EM_DASH = `\u2014`;
/**
 * {@link https://unicode-table.com/en/00AB/ Left-Pointing Double Angle Quotation Mark}
 */
const CHAR_LAQUO = `\u00AB`;
/**
 * {@link https://unicode-table.com/en/00BB/ Right-Pointing Double Angle Quotation Mark}
 */
const CHAR_RAQUO = `\u00BB`;
/**
 * {@link https://unicode-table.com/en/002D/ Hyphen (minus sign)}
 * is used to combine words.
 * @example well-behaved
 * ___
 * Don't confuse with {@link CHAR_EN_DASH} or {@link CHAR_EM_DASH}!
 */
const CHAR_HYPHEN = `\u002D`;
/**
 * {@link https://unicode-table.com/en/2212/ Minus}
 * is used as math operator symbol or before negative digits.
 * ---
 * Can be used as `&minus;`. Don't confuse with {@link CHAR_HYPHEN}
 */
const CHAR_MINUS = `\u2212`;
/**
 * {@link https://unicode-table.com/en/002B/ Plus}
 */
const CHAR_PLUS = `\u002B`;
/**
 * {@link https://unicode-table.com/en/2022/ Bullet}.
 */
const CHAR_BULLET = `\u2022`;
/**
 * {@link https://unicode-table.com/en/2026/ Suspension points}.
 */
const CHAR_ELLIPSIS = `\u2026`;
/**
 * {@link https://unicode-table.com/en/00A4/ Suspension points}.
 */
const CHAR_CURRENCY_SIGN = `\u00A4`;
/**
 * {@link https://unicode-table.com/en/200b/ Suspension points}.
 */
const CHAR_ZERO_WIDTH_SPACE = `\u200B`;

/**
 * @description:
 * AUTOGENERATED
 *
 * Array of icons used in taiga-ui components
 */
const TUI_USED_ICONS = [`tuiIconMirMono`, `tuiIconVisaMono`, `tuiIconElectronMono`, `tuiIconMastercard`, `tuiIconMaestro`, `tuiIconAmex`, `tuiIconDinersClub`, `tuiIconDiscover`, `tuiIconHumo`, `tuiIconJCB`, `tuiIconRuPay`, `tuiIconUnionPay`, `tuiIconUzcard`, `tuiIconVerve`, `tuiIconLink`, `tuiIconSearch`, `tuiIconSun`, `tuiIconMoon`, `tuiIconCode`, `tuiIconMenuLarge`, `tuiIconRotate`, `tuiIconArrowLeft`, `tuiIconArrowRight`, `tuiIconPlus`, `tuiIconMinus`, `tuiIconMinimize`, `tuiIconEye`, `tuiIconEyeOff`, `tuiIconDrag`, `tuiIconSortAscending`, `tuiIconSortDescending`, `tuiIconSortOff`, `tuiIconCheck`, `tuiIconCheckLarge`, `tuiIconMinusLarge`, `tuiIconChevronUp`, `tuiIconHelpCircle`, `tuiIconClose`, `tuiIconAlertCircle`, `tuiIconChevronRight`, `tuiIconInfo`, `tuiIconCheckCircle`, `tuiIconXCircle`, `tuiIconChevronLeft`, `tuiIconStarLarge`, `tuiIconChevronDown`, `tuiIconChevronDownLarge`, `tuiIconFileLarge`, `tuiIconTrashLarge`, `tuiIconAlertCircleLarge`, `tuiIconCheckCircleLarge`, `tuiIconCopy`, `tuiIconCopyLarge`, `tuiIconEyeOffLarge`, `tuiIconEyeLarge`, `tuiIconClock`, `tuiIconClockLarge`, `tuiIconToggleOff`, `tuiIconToggleOffLarge`, `tuiIconToggleOn`, `tuiIconToggleOnLarge`, `tuiIconCalendar`, `tuiIconCalendarLarge`];
const TUI_VERSION = `3.53.0`;

/**
 * Generated bundle index. Do not edit.
 */

export { ALWAYS_FALSE_HANDLER, ALWAYS_TRUE_HANDLER, CHAR_BULLET, CHAR_CURRENCY_SIGN, CHAR_ELLIPSIS, CHAR_EM_DASH, CHAR_EN_DASH, CHAR_HYPHEN, CHAR_LAQUO, CHAR_MINUS, CHAR_NO_BREAK_SPACE, CHAR_PLUS, CHAR_RAQUO, CHAR_ZERO_WIDTH_SPACE, CHROMIUM_EDGE_START_VERSION, EMPTY_ARRAY, EMPTY_CLIENT_RECT, EMPTY_FUNCTION, EMPTY_QUERY, POLLING_TIME, TUI_DEFAULT_IDENTITY_MATCHER, TUI_DEFAULT_MATCHER, TUI_DEFAULT_STRINGIFY, TUI_PARENT_ANIMATION, TUI_PARENT_STOP, TUI_STRICT_MATCHER, TUI_USED_ICONS, TUI_VERSION, svgNodeFilter };
