import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TuiTableModule } from '@taiga-ui/addon-table';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiButtonModule, TuiRootModule } from '@taiga-ui/core';
import { Tournament } from '../../model/tournament';
import { TournamentsService } from '../../services/tournaments.service';

@Component({
  selector: 'app-tournaments',
  standalone: true,
  imports: [CommonModule, TuiTableModule, TuiRootModule, TuiLetModule, TuiButtonModule],
  templateUrl: './tournaments.component.html',
  styleUrl: './tournaments.component.scss'
})
export class TournamentsComponent {
  // TODO use keys of Tournament
  columns = ['name', 'directorName', 'course', 'startDate', 'endDate', 'actions'];

  tournamentsService = inject(TournamentsService);

  tournaments = this.tournamentsService.getAll();

  edit(tournament: Tournament): void {
    console.log('__edit tournament', tournament.name);
  }

  remove(tournament: Tournament): void {
    console.log('__remove tournament', tournament.name);
  }

}
