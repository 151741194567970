import { TuiMonthPipe, tuiDropdownOptionsProvider, TUI_VALUE_ACCESSOR } from '@taiga-ui/core';
import { TUI_MONTH_FORMATTER } from '@taiga-ui/kit/tokens';
import { of, identity } from 'rxjs';
import { map } from 'rxjs/operators';
import { Optional, Self } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
const TUI_MONTH_FORMATTER_PROVIDER = {
  provide: TUI_MONTH_FORMATTER,
  deps: [TuiMonthPipe],
  useFactory: pipe => month => month ? pipe.transform(month).pipe(map(formatted => `${formatted} ${month.formattedYear}`)) : of(``)
};
const FIXED_DROPDOWN_CONTROLLER_PROVIDER = tuiDropdownOptionsProvider({
  limitWidth: `fixed`,
  align: `right`
});
const TUI_VALUE_ACCESSOR_PROVIDER = {
  provide: TUI_VALUE_ACCESSOR,
  deps: [[new Optional(), new Self(), NG_VALUE_ACCESSOR]],
  useFactory: identity
};

/**
 * Generated bundle index. Do not edit.
 */

export { FIXED_DROPDOWN_CONTROLLER_PROVIDER, TUI_MONTH_FORMATTER_PROVIDER, TUI_VALUE_ACCESSOR_PROVIDER };
