import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { TUI_ALERT_POSITION, TuiModeModule, TuiRootModule } from '@taiga-ui/core';
import { environment } from '../environments/environment.prod';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom([
      TuiRootModule,
      TuiModeModule,
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideAuth(() => getAuth()),
      provideFirestore(() => getFirestore())
    ]),
    provideRouter(routes),
    provideClientHydration(),
    provideAnimations(),
    {
      provide: TUI_ALERT_POSITION,
      useValue: '2rem 3rem 0 auto'
    }
  ],

};
