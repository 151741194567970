import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TuiThemeNightService } from '@taiga-ui/addon-doc/services';
import { TuiButtonModule } from '@taiga-ui/core';

@Component({
  selector: 'app-theme-switcher',
  standalone: true,
  imports: [CommonModule, TuiButtonModule],
  template: `
    <button
      tuiIconButton
      appearance="secondary"
      size="s"
      shape="rounded"
      class="mb-1"
      [icon]="night.value ? 'tuiIconSun' : 'tuiIconMoon'"
      (click)="night.toggle()"
    ></button>
  `,
})
export class ThemeSwitcherComponent {
  night = inject(TuiThemeNightService);
}
