<table tuiTable [columns]="columns">
  <thead>
    <tr tuiThGroup>
      <th *tuiHead="'name'" tuiTh>Name</th>
      <th *tuiHead="'directorName'" tuiTh>Director</th>
      <th *tuiHead="'course'" tuiTh>Course</th>
      <th *tuiHead="'startDate'" tuiTh>Start</th>
      <th *tuiHead="'endDate'" tuiTh>End</th>
      <th *tuiHead="'actions'" tuiTh [sorter]="null"></th>
    </tr>
  </thead>
  @if(tournaments | async; as tournaments) {
  <tbody
    *tuiLet="tournaments | tuiTableSort as sortedTournaments"
    tuiTbody
    [data]="sortedTournaments"
  >
    <tr *ngFor="let item of sortedTournaments; let index = index" tuiTr>
      <td *tuiCell="'name'" tuiTd>
        {{ item.name }}
      </td>
      <td *tuiCell="'directorName'" tuiTd>
        {{ item.directorName }}
      </td>
      <td *tuiCell="'course'" tuiTd>
        {{ (item.course | async)?.name }}
      </td>
      <td *tuiCell="'startDate'" tuiTd>
        {{ item.startDate | date }}
      </td>
      <td *tuiCell="'endDate'" tuiTd>
        {{ item.endDate | date }}
      </td>
      <td *tuiCell="'actions'" tuiTd>
        <button
          appearance="flat"
          icon="tuiIconEdit2"
          shape="rounded"
          size="s"
          title="Edit"
          tuiIconButton
          type="button"
          (click)="edit(item)"
          class="action-button"
        ></button>
        <button
          appearance="flat"
          icon="tuiIconTrash"
          shape="rounded"
          size="s"
          title="Remove"
          tuiIconButton
          type="button"
          (click)="remove(item)"
          class="action-button"
        ></button>
      </td>
    </tr>
  </tbody>
  }
</table>
