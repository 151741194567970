import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { NavigationComponent } from '../navigation/navigation.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, HeaderComponent, NavigationComponent, RouterModule],
  template: `
    <app-header />
    <div class="d-flex">
      <!-- <app-navigation /> -->
      <div class="content p-2">
        <router-outlet />
      </div>
    </div>
  `,
  styles: `
    .content {
      margin: 0 auto;
      width: 100%;
      max-width: 1200px;
    }
  `
})
export class HomeComponent {

}
