import { DocumentReference, Timestamp } from 'firebase/firestore';
import { Course } from './course';
import { Observable } from 'rxjs';
import { docData } from '@angular/fire/firestore';

export type TournamentDto = {
  id: string;
  name: string;
  course: DocumentReference<Course>;
  directorName: string;
  startDate: Timestamp;
  endDate: Timestamp;
  pdgaClass: 'A' | 'B' | 'C' | undefined;
}

export type Tournament = {
  id: string;
  name: string;
  course: Observable<Course | undefined>;
  directorName: string;
  startDate: Date;
  endDate: Date;
  pdgaClass: 'A' | 'B' | 'C' | undefined;
}

export const toTournament = (tournamentDto: TournamentDto): Tournament => {
  return {
    id: tournamentDto.id,
    name: tournamentDto.name,
    course: docData(tournamentDto.course),
    directorName: tournamentDto.directorName,
    startDate: tournamentDto.startDate.toDate(),
    endDate: tournamentDto.endDate.toDate(),
    pdgaClass: tournamentDto.pdgaClass
  }
}

