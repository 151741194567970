import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { TuiAvatarModule } from '@taiga-ui/kit';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [CommonModule, TuiAvatarModule],
  template: `
    <div class="d-flex gap-4">
      <tui-avatar
        size="xl"
        [text]="(user$ | async)?.displayName || ''"
        avatarUrl="tuiIconUser"
        [rounded]="true"
      />

      <div class="user-data w-0 flex-grow-1">
        <pre>{{ user$ | async | json }}</pre>
      </div>
    </div>
  `,
  styles: `
    pre {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `
})
export class ProfileComponent {
  user$ = inject(AuthService).currentUser$;
}
