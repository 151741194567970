<form class="login-form" [formGroup]="loginForm">
  <span class="tui-text_h3 tui-space_bottom-10">Sign in</span>

  <tui-input formControlName="email">
    Your email
    <input tuiTextfield type="email" autocomplete="username" />
  </tui-input>

  <tui-input formControlName="password">
    Your password
    <input tuiTextfield type="password" autocomplete="current-password" />
  </tui-input>

  <button tuiButton type="button" (click)="login()">
    Login
  </button>

  <a routerLink="/register">Don't have an account yet? Register</a>
</form>
