import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
  TuiThemeNightService
} from '@taiga-ui/addon-doc/services';
import { TuiAlertModule, TuiDialogModule, TuiModeModule, TuiRootModule, TuiThemeNightModule } from '@taiga-ui/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TuiRootModule,
    TuiModeModule,
    TuiThemeNightModule,
    TuiDialogModule,
    TuiAlertModule,
    RouterOutlet,
  ],
})
export class AppComponent {
  title = 'DG Marker';

  night = inject(TuiThemeNightService);

  dark$ = this.night.asObservable();
}
